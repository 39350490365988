.fieldGroup {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.fieldGroup fieldset:disabled {
  opacity: 0.5;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
