.titleLink[href] {
  color: #888;
}

.slash {
  color: #888;
  padding: 0 0.25em;
}

.userRow {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 22px;
  column-gap: 3em;
  margin-bottom: 2em;
}

.passwordResetButton {
  display: inline-block;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--button-blue);
  padding: 1px 0;
  font-size: 0.75em;
  font-weight: normal;
}

.passwordResetButton:hover {
  text-decoration: underline;
}

.actionBar {
  display: flex;
  justify-content: space-around;
  border-top: var(--border);
  gap: 15px;
  padding: 15px;
}

.spinner {
  display: block;
  align-self: center;
  font-size: 26px;
  color: #888;
  line-height: 0;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
