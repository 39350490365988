.view {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  width: 100%;
}

.row pre {
  margin: 0;
}
