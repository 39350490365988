.p5RAHa_textButton {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  color: var(--button-blue);
  background: none;
  border: none;
  padding: 4px 6px;
  display: inline-block;
}

.p5RAHa_textButton:hover {
  text-decoration: underline;
}

.p5RAHa_textButtonError {
  color: var(--highlight-red);
}

.dofI0W_fieldGroup {
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  display: grid;
}

.dofI0W_fieldGroup fieldset:disabled {
  opacity: .5;
}

.dofI0W_footer {
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

._0Zj4hq_titleLink[href] {
  color: #888;
}

._0Zj4hq_slash {
  color: #888;
  padding: 0 .25em;
}

._0Zj4hq_userRow {
  grid-template-columns: 1fr 2fr 2fr 22px;
  column-gap: 3em;
  margin-bottom: 2em;
  display: grid;
}

._0Zj4hq_passwordResetButton {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  color: var(--button-blue);
  background: none;
  border: none;
  padding: 1px 0;
  font-size: .75em;
  font-weight: normal;
  display: inline-block;
}

._0Zj4hq_passwordResetButton:hover {
  text-decoration: underline;
}

._0Zj4hq_actionBar {
  border-top: var(--border);
  justify-content: space-around;
  gap: 15px;
  padding: 15px;
  display: flex;
}

._0Zj4hq_spinner {
  color: #888;
  align-self: center;
  font-size: 26px;
  line-height: 0;
  animation: 1.5s linear infinite _0Zj4hq_spin;
  display: block;
}

@keyframes _0Zj4hq_spin {
  to {
    transform: rotate(360deg);
  }
}

.FI9CVG_view {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.FI9CVG_row {
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 100%;
  display: flex;
}

.FI9CVG_row pre {
  margin: 0;
}

/*# sourceMappingURL=EventSettings.c7f38733.css.map */
